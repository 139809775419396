import Image from 'next/image'

// import backgroundImage from '@/images/auth-background.png'
import backgroundImage from '@/images/background-features.jpg'
import VolopayLogo from '@/images/testimonials/volopaylogo.png'
import { useRouter } from 'next/router'
import { useEffect, useContext } from 'react'
import { CatchPromise } from '@/utils/catch-promise'
import api from '@/api'
import { CheckBadgeIcon } from '@heroicons/react/20/solid'
import { ChevronRightIcon, StarIcon } from '@heroicons/react/20/solid'
import {
  Bars3Icon,
  XMarkIcon,
  StarIcon as OutlineStar,
} from '@heroicons/react/24/outline'
import { G2Icon } from '@/components/core/icons/G2Icon'
// import { TrackerContext } from '@/contexts/tracker-context'
export function AuthLayout({ children }) {
  const router = useRouter()
  // const { initTracker, startTracking } = useContext(TrackerContext)

  async function checkUser() {
    const [err, response] = await CatchPromise(api.auth.validate())

    if (response) {
      router.push('/get-started')
    }
  }

  useEffect(() => {
    ; (async () => {
      await checkUser()
      // initTracker()
      // startTracking()
    })()
    return () => { }
  }, [])

  return (
    <div className="relative h-screen items-center justify-center py-[100px] sm:grid sm:grid-cols-2 sm:px-10 md:px-12 lg:px-20">
      <div className="mx-auto hidden max-w-[600px] px-10 sm:block">
        <div className="text-left text-2xl font-semibold">
          Say goodbye to the hassle of using multiple tools for{' '}
          <span className="text-indigo-500">Email</span> and{' '}
          <span className="text-green-500">WhatsApp</span> marketing &
          automation.
        </div>
        <ul className="m-0 mt-5 p-0 text-left text-lg text-gray-500">
          <li className="flex items-center">
            <CheckBadgeIcon className="mr-2 h-4 w-4 text-green-600" /> Send
            emails and WhatsApp messages from one platform
          </li>
          <li className="flex items-center">
            <CheckBadgeIcon className="mr-2 h-4 w-4 text-green-600" /> Automate
            customer journeys with our visual builder
          </li>
          <li className="flex items-center">
            <CheckBadgeIcon className="mr-2 h-4 w-4 text-green-600" /> Save upto
            50% as compared to other tools in the market
          </li>
        </ul>
        <a
          href="https://www.g2.com/products/campaignhq-campaignhq/reviews"
          className="mt-10 flex flex-col items-center space-y-3 sm:w-[400px] sm:flex-row sm:space-x-3 sm:space-y-0"
        >
          <div className="flex">
            <StarIcon className="mx-auto h-auto w-4 text-[#ff492c]" />
            <StarIcon className="mx-auto h-auto w-4 text-[#ff492c]" />
            <StarIcon className="mx-auto h-auto w-4 text-[#ff492c]" />
            <StarIcon className="mx-auto h-auto w-4 text-[#ff492c] " />
            <div className="relative overflow-hidden">
              <OutlineStar className="mx-auto h-auto w-4 text-[#ff492c]" />
              <div className="absolute  top-0 z-10 w-2 overflow-hidden text-[#ff492c]">
                <StarIcon className="text-yellow mx-auto h-auto w-4" />
              </div>
            </div>
          </div>
          <div className="text-gray-500">Rated 4.6/5 on</div>
          <G2Icon
            className="mx-auto h-auto w-[25px] text-[#ff492c]"
            title="CampaignHQ is rated 4.6 on G2"
          />
        </a>
      </div>
      <div className="relative mx-auto w-full px-2 sm:max-w-[550px] sm:px-10 md:flex-none">
        <div className="rounded border border-gray-200 bg-white shadow-lg shadow-indigo-200">
          <div className="w-full p-10 md:min-w-[450px]">{children}</div>
        </div>
      </div>
    </div>
  )
}
